
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React, { useEffect } from 'react';
import '@/styles/globals.css';
import '@/styles/prism-atom-dark.css';
import type { AppProps } from 'next/app';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import NextNProgress from 'nextjs-progressbar';
import { useRouter } from 'next/router';
import Image from 'next/image';
import PageInsightVercel from '@/components/PageInsightVercel';
import { handleGTMView } from '@/helpers/handleGTMView';
import img from '../img/load.png';
const theme = extendTheme({
    styles: {
        global: {
            body: {
                minHeight: '100vh',
                backgroundColor: 'gray.50'
            },
            '.onBanner + .show': {
                display: 'block'
            },
            '.share-wrapper button:hover': {
                backgroundColor: '#BEE3F8 !important'
            }
        }
    }
});
function App({ Component, pageProps }: AppProps) {
    const { pathname } = useRouter();
    useEffect(() => {
        const mainDataLayer = {
            pageTypeName: pageProps.page || null,
            url: pathname
        };
        handleGTMView(mainDataLayer);
        document.body.onload = () => {
            const loading = document.getElementById('loading');
            if (loading) {
                loading.style.display = 'none';
            }
        };
    }, [pageProps, pathname]);
    return (<>
      <Image src={img} alt="loading" id="loading" fill priority style={{ zIndex: -2 }}/>
      <ChakraProvider theme={theme}>
        <NextNProgress height={3}/>
        <Component {...pageProps}/>
        <PageInsightVercel />
      </ChakraProvider>
    </>);
}

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: typeof __i18nConfig.loadLocaleFrom === 'function' ? __i18nConfig.loadLocaleFrom : (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  
import { getCookie } from 'cookies-next';

export const handleGTMView = (data?: unknown) => {
  const hasAccepted = getCookie('acceptCookies');

  if (!hasAccepted) return;

  const viewEvent = {
    ...(data ?? {}),
    event: 'VirtualPageView',
  };

  // @ts-ignore
  window.dataLayer?.push(viewEvent);
};
